import {
  ref, reactive, getCurrentInstance, onMounted
} from 'vue'
import { insuranceUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
// eslint-disable-next-line object-curly-newline
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'

export default {
  props: ['dataEdit'],
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const id = ref('')
    const title = ref('')
    const dataForm = reactive({
      name: '',
      address: '',
      telp: null as any,
      isActive: false,
      premi: 0,
      // attr2: '',
      // attr3: ''
    }) as any
    const submitted = ref(false)
    const { dataEdit } = reactive(props)

    const validTelpX = (val: any = '') => {
      let valid = true
      console.log(val)
      if (val) {
        if (val.length > 1) {
          if (val.charAt(0) !== '0') {
            if (val.slice(0, 2) !== '62') {
              console.log('1', val.slice(0, 2))
              valid = false
            }
          }
        } else if (val.length === 1) {
          if (val.charAt(0) !== '0' || val.charAt(0) !== '6') {
            console.log('2', val.charAt(1))
            valid = false
          }
        }
        // "$response": "TypeError: Cannot read properties of null (reading 'length')"
      }

      console.log(valid)
      return valid
    }

    const rules = {
      name: {
        required: helpers.withMessage('Nama Asuransi harus diisi', required)
      },
      address: {
        required: helpers.withMessage('Alamat Asuransi harus diisi', required)
      },
      telp: {
        required: helpers.withMessage('Telepon harus diisi', required),
        validTelp: helpers.withMessage('Telepon harus diawali dengan 0 atau 62', validTelpX),
        maxLength: helpers.withMessage('Maksimal 15 karakter', maxLength(15)),
        minLength: helpers.withMessage('Minimal 10 karakter', minLength(10)),
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const definitionDataForm = (val: any) => {
      if (val) {
        title.value = 'Edit Asuransi'
        id.value = val.Id
        dataForm.telp = val.Telepon
        dataForm.address = val.Alamat
        dataForm.name = val.Nama
        dataForm.isActive = val.IsActive
        dataForm.premi = val.Premi === null || val.Premi === '' ? 0 : Number(val.Premi)
        // dataForm.attr2 = val.Attribute2
        // dataForm.attr3 = val.Attribute3
      } else {
        title.value = 'Tambah Asuransi'
        id.value = ''
        dataForm.telp = null
        dataForm.address = ''
        dataForm.name = ''
        dataForm.isActive = true
        dataForm.premi = ''
        // dataForm.attr2 = ''
        // dataForm.attr3 = ''
      }
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const submitProccess = async () => {
      const data = {
        Nama: dataForm.name,
        Telepon: dataForm.telp,
        Alamat: dataForm.address,
        IsActive: dataForm.isActive,
        Premi: dataForm.premi === null || dataForm.premi === '' ? '0' : Number(dataForm.premi).toString(),
        // Attribute2: dataForm.attr2,
        // Attribute3: dataForm.attr3
      }
      store.dispatch('showLoading')
      const response = await insuranceUseCase.submitData(id.value, data)
      console.log(response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    onMounted(() => {
      definitionDataForm(dataEdit)
    })

    return {
      title,
      dataForm,
      id,
      v$,
      submitted,
      submitData,
      closeSidebar
    }
  }
}
